import React from 'react';
import styled from 'styled-components/macro';
import { UHCDarkText } from '@rally/rewards-view/color';

const updated = '10.01.2024 at 12:01AM CT';
const CMS = 'Y0066_240913_101233_M';

const Legal = () => (
  <LegalContainer>
    <Text>&copy;2024 United HealthCare Services, Inc. All rights reserved. </Text>
    <Text>
     Last updated: {updated} | {CMS}
    </Text>
  </LegalContainer>
);

const LegalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-right: 50px;
`;

const Text = styled.p`
  color: ${UHCDarkText};
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  margin: 4px 0;
`;

export { Legal };
